<template>
    <div class="container rtl">
        <div class="row pb-4">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 text-center">
                        <img width="100%" :src="banner">
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-1 text-left">
                        <h4 class="text-left">بەڕێز</h4>
                    </div>
                    <div class="col-3 text-center">
                        <h5 class="text-center">{{invoice.customer_name}}</h5>
                    </div>
                    <div class="col-1 text-left">
                        <h4 class="text-left">ژ.ت</h4>
                    </div>
                    <div class="col-3 text-center">
                        <h5 class="text-center"><a style="direction:rtl!important"
                                    :href="'tel:'+invoice.customer_phone">{{invoice.customer_phone}}</a></h5>
                    </div>
                    <div class="col-1 text-left">
                        <h4 class="text-left">ژ.پسوڵە</h4>
                    </div>
                    <div class="col-3 text-center">
                        <h5 class="text-center">{{invoice.invoice_number}}</h5>
                    </div>
                </div>  
                <div class="row mt-4">
                    <div class="col-1 text-left">
                        <h4 class="text-left">بەروار</h4>
                    </div>
                    <div class="col-3 text-center">
                        <h5 class="text-center">{{invoice.invoice_date}}</h5>
                    </div>
                    <div class="col-1 text-left">
                        <h4 class="text-left">ناونیشان</h4>
                    </div>
                    <div class="col-7 text-center">
                        <h5 class="text-center">
                            <a name="" id=""  target="_blank"
                                :href="'https://www.google.com/maps/dir/?api=1&destination=' + invoice.customer_latitude + ',' + invoice.customer_longitude"
                                role="button">
                                {{invoice.city_name_ku}} - {{invoice.town_name_ku}} - {{invoice.place_name_ku}} - {{invoice.customer_location}}
                            </a>
                        </h5>
                    </div>
                </div>  
            </div>
        </div>
        <form @submit.prevent="addInvoiceItems" autocomplete="off" class="no-print">
            <div class="row">
                <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                    <label>کۆدی بەرهەم</label>
                    <div class="form-group">
                        <input v-model="invoice_item.item_barcode" @input="checkBarcode" class="form-control" required>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                    <label>ناوی بەرهەم</label>
                    <div class="form-group">
                        <select class="form-control" @change="checkItemID" v-model="invoice_item.item_id" required>
                            <option v-for="(item, index) in items" :key="index" :value="item.item_id">
                                {{item.item_name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                    <label>فرۆشتن بە</label>
                    <div class="form-group">
                        <select class="form-control" v-model="invoice_item.buy_type" required @change="checkItemID">
                            <option value="dana">{{invoice_item.item_unit_tak}}</option>
                            <option value="karton">{{invoice_item.item_unit}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                    <label>بڕی ماوە</label>
                    <div class="input-group mb-3">
                        <input v-model="temp_item.last_count" disabled class="form-control" type="number" step="any">
                        <div class="input-group-prepend">
                            <span class="input-group-text"
                                id="basic-addon1">{{invoice_item.buy_type == 'dana' ? invoice_item.item_unit_tak : invoice_item.item_unit}}</span>
                        </div>
                    </div>
                </div>

                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                    <label>نرخ</label>
                    <div class="input-group mb-3">
                        <input v-model="invoice_item.invoice_items_iqd" class="form-control" type="number" step="any"
                            required>
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">د.ع</span>
                        </div>

                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                    <label>بڕی داواکراو</label>
                    <input v-model="invoice_item.invoice_items_qty" :max="temp_item.last_count" class="form-control"
                        type="number" step="any" required>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <button type="submit" :disabled="!editable" class="btn-block btn btn-success">زیادکردنی
                            کاڵا</button>
                    </div>
                </div>
            </div>
        </form>
        <div class="row mt-2">
            <div class="col">
                <table class="my-table" border="1">
                    <thead>

                        <tr>
                            <!-- <th scope="col" class="text-center" rowspan="2"> بارکۆد </th> -->
                            <th scope="col" class="text-center" rowspan="2"> ناوی بەرهەم </th>
                            <th scope="col" class="text-center" rowspan="2"> جۆر </th>
                            <th scope="col" class="text-center">بڕ </th>
                            <!-- Removed Large Price Unit -->
                            <!-- <th scope="col" class="text-center" colspan="2">بڕ </th> -->
                            <!-- <th scope="col" class="text-center" colspan="2"> نرخی دینار </th> -->
                            <!-- Removed Large Price Unit -->
                            <th scope="col" class="text-center"> نرخی دینار </th>
                            <th scope="col" class="text-center" rowspan="2"> کۆی نرخی دینار </th>
                            <th scope="col" class="text-center no-print" rowspan="2">گۆڕانکاری</th>
                            <th scope="col" class="text-center no-print" rowspan="2">سڕینەوە</th>
                        </tr>
                        <!-- <tr>
                            <th class="text-center">تاک</th>
                            <th class="text-center">کۆ</th>
                            <th class="text-center">تاک</th>
                            <th class="text-center">کۆ</th>

                        </tr> -->
                    </thead>
                    <tbody class="text-center">
                        <tr class="no-print" v-for="(item, index) in invoice.items" :key="index">
                            <!-- <td>{{item.item_barcode}}</td> -->
                            <td>{{item.item_name}}</td>
                            <td>{{item.category_name}}</td>
                            <td class="text--center">
                                <div class="input-group mx-auto" style="width:150px">
                                    <input :disabled="!editable" v-model="item.invoice_items_qty"
                                        @input="changeQty(index , 'one')" class="form-control" type="number" step="any"
                                        required>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">{{item.item_unit_tak}}</span>
                                    </div>
                                </div>
                            </td>
                            <!-- <td>
                                <div class="input-group mx-auto" style="width:150px">
                                    <input :disabled="!editable" v-model="item.count_many" class="form-control" @input="changeQty(index , 'many')" type="number"
                                        step="any" required>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">{{item.item_unit}}</span>
                                    </div>
                                </div>
                            </td> -->
                            <td>
                                <div class="input-group mx-auto" style="width:150px">
                                    <input :disabled="!editable" v-model="item.invoice_items_iqd" class="form-control"
                                        @input="changePrice(index , 'one')" type="number" step="any" required>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">د.ع</span>
                                    </div>
                                </div>
                                <!-- {{$service.toIQD(item.invoice_items_iqd)}} -->
                            </td>
                            <!-- <td>
                                <div class="input-group mx-auto" style="width:180px">
                                    <input :disabled="!editable" v-model="item.many_iqd" class="form-control" @input="changePrice(index , 'many')" type="number"
                                        step="any" required>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">د.ع</span>
                                    </div>
                                </div>
                                {{$service.toIQD(item.many_iqd)}}

                            </td> -->
                            <td>{{$service.toIQD(item.iqd)}}</td>
                            <td class="no-print">
                                <button :disabled="!editable" @click="updateItem(index)" type="button"
                                    class="btn btn-success btn-sm">
                                    گۆڕانکاری
                                    <i class="fa fa-pen" aria-hidden="true"></i>
                                </button>
                            </td>
                            <td class="no-print">
                                <button :disabled="!editable" @click="openinvoice_items(item , 'remove')" type="button"
                                    class="btn btn-danger btn-sm">
                                    سڕینەوە
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                        <tr class="" v-for="(item) in invoice.items" :key="item.invoice_items_id">
                            <!-- <td>{{item.item_barcode}}</td> -->
                            <td>{{item.item_name}}</td>
                            <td>{{item.category_name}}</td>
                            <td class="text--center">
                                {{item.invoice_items_qty}}<span>{{item.item_unit_tak}}</span>
                            </td>
                            <!-- <td class="text--center">
                                {{item.count_many}}<span>{{item.item_unit}}</span>
                            </td> -->
                            <td class="text--center">
                                {{$service.toIQD(item.invoice_items_iqd)}}
                            </td>
                            <!-- <td class="text--center">
                                {{$service.toIQD(item.many_iqd)}}
                            </td> -->
                            <td>{{$service.toIQD(item.iqd)}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th class="text-center" colspan="2">کۆی گشتی پسوڵە</th>
                            <td class="text-center">{{invoice.c_one}} بڕ</td>
                            <!-- <td class="text-center">{{invoice.c_many ? invoice.c_many : ''}}</td> -->
                            <td class="text-center"></td>
                            <td class="text-center">{{$service.toIQD(invoice.t_iqd)}}</td>
                            <!-- <td class="text-center no-print"></td> -->
                        </tr>
                        <tr>
                            <th class="text-center" colspan="2">نرخی گەیاندن </th>
                           
                            <td class="text-center" colspan="2"></td>
                            <td class="text-center">{{$service.toIQD(parseFloat(invoice.invoice_delivery_price))}}</td>
                            <!-- <td class="text-center no-print"></td> -->
                        </tr>
                        <tr>
                            <th class="text-center" colspan="2">بالانسی سین ستۆر</th>
                            <!-- <td class="text-center">{{invoice.c_many ? invoice.c_many : ''}}</td> -->
                            <td class="text-center" colspan="2"></td>
                            <td class="text-center">{{$service.toIQD(parseFloat(invoice.invoice_network_balance))}}</td>
                            <!-- <td class="text-center no-print"></td> -->
                        </tr>
                        <tr>
                            <th class="text-center" colspan="2">کۆی گشتی نرخ</th>
                            <!-- <td class="text-center">{{invoice.c_many ? invoice.c_many : ''}}</td> -->
                            <td class="text-center" colspan="2"></td>
                            <td class="text-center">{{$service.toIQD(invoice.t_iqd- parseFloat(invoice.invoice_network_balance) + parseFloat(invoice.invoice_delivery_price))}}</td>
                            <!-- <td class="text-center no-print"></td> -->
                        </tr>
                        
                    </tfoot>
                </table>
            </div>
        </div>
        <div class="row no-print my-4">
            <div class="col">
                <h4>کردارەکان لەسەر پسوڵە</h4>
            </div>
        </div>
        <div class="row no-print">
            <div class="col-6">
                <table class="table">
                    <tbody>
                        <tr>
                            <td>حاڵەت</td>
                            <td>
                                <div class="form-group">
                                    <select :disabled="!editable" class="form-control" v-model="invoice.invoice_status"
                                        name="" id="">
                                        <option :class="`text--${item.color}`" :value="item.value"
                                            v-for="(item, index) in invoice_status_list" :key="index">
                                            {{item.text}}
                                        </option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <button type="button" :disabled="!editable" class="btn-block btn btn-primary"
                                    @click="updateInvoiceStatus">
                                    گۆڕانکاری
                                    <i class="fas fa-pen"></i>
                                </button>
                            </td>
                        </tr>
                        <!-- <tr>
                            <td colspan="2">سڕینەوەی پسوڵە</td>
                            <td>
                                <button :disabled="!editable" type="button" class=" btn-block btn btn-danger">
                                    سڕینەوە
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr> -->
                        <tr>
                            <td colspan="3">
                                <button type="button" @click="print()" class="btn btn-block btn-info">
                                    چاپکردن
                                    <i class="fa fa-print" aria-hidden="true"></i>
                                </button>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Delete Modal -->
        <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">سڕینەوەی بەرهەمی سەر پسوڵەی کۆمپانیا</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                        <button type="button" class="btn btn-danger" @click="deleteinvoice_items">سڕینەوە</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                banner: this.$banner,
                invoice_id: 0,
                invoice: {},
                editable: true,
                selected_item: {
                    invoice_items_usd: 0,
                    invoice_items_iqd: 0,
                },
                invoice_item: {
                    invoice_items_usd: 0,
                    invoice_items_iqd: 0,
                    buy_type: 'karton'
                },
                resetable: false,
                temp_item: {},
                msg: {
                    color: '',
                    model: false,
                    text: ''
                }
            }
        },
        computed: {
            items() {
                return this.$store.getters.items.filter(f => {
                    return f.store_id == this.invoice.store_id
                })
            },
            dollar() {
                return this.$store.getters.dollar
            },
            invoice_status_list() {
                return this.$store.getters.invoice_status_list
            },
        },
        methods: {
            print() {
                window.print()
            },
            checkStatus(s) {
                let list = this.invoice_status_list.filter(f => {
                    return f.value == s
                })

                return list[0]
            },
            changeQty(index, type) {
                let item = this.invoice.items[index]
                if (type == 'one') {
                    this.invoice.items[index].count_many = (item.invoice_items_qty / item.item_quantity).toFixed(3)
                } else if (type == 'many') {
                    this.invoice.items[index].invoice_items_qty = (item.count_many * item.item_quantity).toFixed(0)
                }
            },
            changePrice(index, type) {
                let item = this.invoice.items[index]
                if (type == 'one') {
                    this.invoice.items[index].many_iqd = (item.invoice_items_qty * item.invoice_items_iqd).toFixed(0)
                } else if (type == 'many') {
                    this.invoice.items[index].invoice_items_iqd = (item.many_iqd / item.item_quantity).toFixed(0)
                }
            },
            updateItem(index) {
                let item = this.invoice.items[index]
                axios.post('invoice_items/update.php', {
                        invoice_items_id: item.invoice_items_id,
                        invoice_items_qty: item.invoice_items_qty,
                        invoice_items_iqd: item.invoice_items_iqd
                    })
                    .then(r => {
                        console.log(r);
                        this.getInvoiceItems()
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            checkLast(item) {
                let count = this.invoice_item.last_count
                if (this.invoice_item.buy_type == 'dana') {
                    this.temp_item.last_count = count
                    this.invoice_item.invoice_items_iqd = (item.item_price * this.dollar).toFixed(0)
                } else {
                    this.invoice_item.invoice_items_iqd = (item.item_price * this.invoice_item.item_quantity * this
                        .dollar).toFixed(0)
                    this.temp_item.last_count = (count / this.invoice_item.item_quantity).toFixed(2)
                }

            },
            checkBarcode() {
                let check = this.items.filter(f => {
                    return f.item_barcode == this.invoice_item.item_barcode
                })
                if (check[0]) {
                    this.invoice_item.item_id = check[0].item_id
                    this.invoice_item.item_unit = check[0].item_unit
                    this.invoice_item.item_unit_tak = check[0].item_unit_tak
                    this.invoice_item.item_quantity = check[0].item_quantity
                    this.getItem(this.invoice_item.item_id)
                } else {
                    this.invoice_item.item_id = undefined
                }
            },
            getItem(id) {
                this.temp_item = {
                    last_price: 0,
                    last_count: 0
                }

                this.invoice_item.last_price = 0
                this.invoice_item.last_count = 0

                axios.post('items/getAvailable.php', {
                        item_id: id
                    })
                    .then(r => {
                        if (r.data.status == 'ok') {
                            let item = r.data.items ? r.data.items[0] : {}
                            this.invoice_item.last_price = item.item_amount
                            this.invoice_item.last_count = item.available_qty
                            this.temp_item.last_price = item.item_amount
                            if (this.invoice.invoice_currency == 'iqd') {
                                this.temp_item.last_price = this.temp_item.last_price * (this.invoice
                                    .invoice_dollar_price / 100)
                            }
                            this.temp_item.last_count = item.available_qty
                            this.checkLast(item)
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            checkItemID() {
                let check = this.items.filter(f => {
                    return f.item_id == this.invoice_item.item_id
                })
                if (check[0]) {
                    this.invoice_item.item_barcode = check[0].item_barcode
                    this.invoice_item.item_unit = check[0].item_unit
                    this.invoice_item.item_unit_tak = check[0].item_unit_tak
                    this.invoice_item.item_quantity = check[0].item_quantity
                    this.getItem(this.invoice_item.item_id)
                } else {
                    this.invoice_item.item_barcode = undefined
                }
            },
            getInvoiceItems() {
                axios.post('invoice/getInvoice.php', {
                        invoice_id: this.invoice_id
                    })
                    .then(r => {
                        console.log();
                        this.invoice = r.data.invoice
                        
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            addInvoiceItems() {
                let params = this.invoice_item
                params.invoice_id = this.invoice_id
                let count = this.invoice_item.invoice_items_qty
                let iqd = this.invoice_item.invoice_items_iqd
                if (this.invoice_item.buy_type == 'karton') {
                    count = this.invoice_item.invoice_items_qty * this.invoice_item.item_quantity
                    params.invoice_items_qty = count

                    iqd = (this.invoice_item.invoice_items_iqd / this.invoice_item.item_quantity)
                    params.invoice_items_iqd = iqd
                }
                params.dollar_price = this.invoice.invoice_dollar_price
                params.currency = this.invoice.invoice_currency
                axios.post('invoice_items/create.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.invoice_item = {
                                invoice_items_usd: 0,
                                invoice_items_iqd: 0,
                                buy_type: 'karton'
                            }
                            this.getInvoiceItems()
                            this.msg = {
                                model: true,
                                text: 'کاڵا  زیادکرا',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                        } else {
                            console.log(r);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            deleteinvoice_items() {
                let params = {
                    invoice_items_id: this.selected_invoice_items.invoice_items_id
                }
                axios.post('invoice_items/delete.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            $('#deleteModal').modal('hide')
                            this.getInvoiceItems()
                            this.msg = {
                                model: true,
                                text: '  کاڵا سڕایەوە',
                                color: 'danger'
                            }
                            this.$toast(this.msg.text);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            calculateBalance() {
                axios.post('customer/checkParents.php', {
                        balance: this.invoice.t_iqd,
                        customer_id: this.invoice.customer_id,
                    })
                    .then(r => {
                        console.log(r);
                    })
                    .catch(e => {})
            },
            updateInvoiceStatus() {
                axios.post('invoice/changeStatus.php', {
                        invoice_status: this.invoice.invoice_status,
                        invoice_id: this.invoice_id,
                    })
                    .then(r => {
                        console.log(r);
                        if (r.data.status == 'ok') {
                            if (this.invoice.invoice_status == 'done') {
                                this.calculateBalance()
                            }
                            this.getInvoiceItems()
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            openinvoice_items(item, type) {
                this.selected_invoice_items = item
                if (type == 'remove') {
                    $('#deleteModal').modal('show')
                }
            }
        },
        mounted() {
            this.invoice_id = this.$route.params.id;
            $('#deleteModal').modal('hide')
            this.getInvoiceItems()
        },
    }
</script>
<style>
    .my-table {
        width: 100%;
        border: 1px solid black;
    }

    .my-table th {
        padding: 10px;
        background-color: #E8EAF6;
        color: black;
    }

    .my-table td {
        padding: 5px;
    }

    .wasl-table {
        width: 100%;
        border: 1px solid black;
    }

    .wasl-table th {
        padding: 10px;
        background-color: #E8EAF6;
    }
</style>